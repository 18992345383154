export const userEntityTypes = {
  customer: "customer",
  department: "department"
};

export const personInputTypes = {
  search: {
    value: "search",
    text: "搜索导入的被试者"
  },
  create: {
    value: "create",
    text: "新建被试者"
  }
};
